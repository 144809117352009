<template>
    <div class="course-finish">
        <button
            type="button"
            class="btn-block-option close-modal"
            @click="$modal.hide('thanks-feedback')"
        >
            ×
        </button>
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="block-content content">
                    <div class="course-finish__wrapper">
                        <div class="course-finish__thanks">
                            <div class="course-finish__thanks-icon"></div>
                            <h3 class="course-finish__title">Спасибо за отзыв</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ThanksFeedback'
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";

.v--modal-overlay .v--modal-box {
    overflow: visible;
}

.course-finish {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 520px;
    padding: 0;

    color: $black;
    background-color: $white;

    @include below($mobile) {
        min-height: 0;
        padding: 40px 0;
        margin-bottom: 20px;
    }

    .close-modal {
        //top: -13px;
        //right: -42px;
    }

    .modal-dialog {
        margin-bottom: 45px;

        @include below($mobile) {
            width: 100%;
            margin-bottom: 30px;
        }
    }

    &__wrapper {
        text-align: center;
    }

    &__feedback-icon {
        margin: 0 auto 30px;
        @include icon($finished, 56px);

        @include below($mobile) {
            margin-bottom: 30px;

            background-size: 56px;
        }
    }

    &__thanks-icon {
        margin: 0 auto 30px;
        @include icon($thanks, 84px);

        @include below($mobile) {
            margin-bottom: 30px;

            background-size: 56px;
        }
    }

    &__title {
        margin-bottom: 21px;

        font-size: 22px;
        line-height: 30px;

        @include below($mobile) {
            width: 80%;
            margin: 0 auto 20px;

            font-size: 22px;
            line-height: 30px;
        }
    }

    &__label {
        display: block;
        margin-bottom: 29px;

        font-size: 16px;
        line-height: 24px;
        font-weight: 600;

        @include below($mobile) {
            margin-bottom: 27px;

            font-size: 16px;
            line-height: 24px;
        }
    }

    &__textarea {
        display: block;

        width: 100%;
        width: 258px;
        min-height: 150px;
        padding: 6px 11px;
        margin: 0 auto 20px;

        font-size: 15px;
        font-weight: 600;

        border-radius: 2px;
        border-color: #D5DBE4;
        resize: vertical;
        outline: 0;
        transition: border-color 0.3s;

        @include below($mobile) {
            width: 95%;

            font-size: 14px;
        }

        &:hover,
        &:focus {
            border-color: #959595;
        }
    }
}

</style>
